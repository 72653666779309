import React from 'react';

import { CustomPicker } from 'react-color';
import { EditableInput, Hue } from 'react-color/lib/components/common';
import grey from '@material-ui/core/colors/grey';
import {  createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';


const theme = createMuiTheme({
  palette: {
    primary: grey,
  },
  typography: {
    useNextVariants: true,
  },
});

export const MyPicker = ({ hex, hsl, onChange }) => {
  const styles = {
    hue: {
      height: 10,
      position: 'relative',
      marginBottom: 10,
    },
    input: {
      borderRadius: 0,
      WebkitAppearance: 'none',
      height: 60,
      border: `1px solid ${ hex }`,
      paddingLeft: 10,
      width: 140,
      fontSize: 20
    },
    swatch: {
      width: 140,
      height: 60,
      background: hex,
    },
  }

  return (
    <div className="TextField-without-border-radius">
      <MuiThemeProvider theme={theme}>
        <div style={{ display: 'flex' }}>
          <EditableInput
            style={{ input: styles.input }}
            value={ hex }
            onChange={ onChange }
          />
          &nbsp; &nbsp; &nbsp;
          <div style={ styles.swatch } />
        </div>
      </MuiThemeProvider>
    </div>
  )
}

export default CustomPicker(MyPicker)
