import React from "react";
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {  createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import ColorPicker from './ColorPicker';
import axios from 'axios';
import { Link } from 'gatsby';
import Drawer from '@material-ui/core/Drawer';
import configuration from './configuration';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
// import Loadable from 'react-loadable';
import BuyPixel from './BuyPixel';
import ChangePixel from './ChangePixel';


const NON_DIGIT = '/[^\d]/g';

const theme = createMuiTheme({
  palette: {
    primary: grey,
  },
  typography: {
    useNextVariants: true,
  },
});

const button_style = {
  button: {
    borderRadius: 0,
    backgroundColor: "black",
    color: 'white',
    padding: "9px 36px",
    fontSize: "24px",
    fontWeight: 'bold',
    textTransform: 'capitalize',
    boxShadow: '0 0px 0px 0px',
    minWidth: 300,
  },
  buttonDisabled: {
    borderRadius: 0,
    backgroundColor: '#EBECF0',
    color: 'white',
    padding: "9px 36px",
    fontSize: "24px",
    fontWeight: 'bold',
    textTransform: 'capitalize',
    boxShadow: '0 0px 0px 0px',
    minWidth: 300,
  },
};

const button_style_pop_up = {
  button: {
    borderRadius: 0,
    backgroundColor: "black",
    color: 'white',
    padding: "9px 36px",
    fontSize: "24px",
    fontWeight: 'bold',
    textTransform: 'capitalize',
    boxShadow: '0 0px 0px 0px',
    minWidth: 300,
  },
  buttonDisabled: {
    borderRadius: 0,
    backgroundColor: '#EBECF0',
    color: 'white',
    padding: "9px 36px",
    fontSize: "24px",
    fontWeight: 'bold',
    textTransform: 'capitalize',
    boxShadow: '0 0px 0px 0px',
    minWidth: 300,
  },
};

const snackbar_style = {
  standardMessage: {
    borderRadius: 0,
    backgroundColor: '#EBECF0',
    color: 'black',
    padding: "8px 20px",
    fontSize: "20px",
    fontWeight: 'light',
    textTransform: 'capitalize',
    boxShadow: '0 0px 0px 0px',
    minWidth: 145,
  }
};

const PixelCoordinates = props => {
  var pixel_number = ''
  var buyOk = false
  // if (props.x_coordinate === '' && props.y_coordinate === '') {pixel_number = ''}
  if (props.x_coordinate !== '' && props.y_coordinate !== '') {
    pixel_number = props.x_coordinate + 1000 * (props.y_coordinate - 1);
    var buyOk = true
  }
  return (
    <div className="TextField-without-border-radius">
      <MuiThemeProvider theme={theme}>
      <TextField
        inputProps={{inputMode: 'numeric', style: {fontSize: 20}}}
        id="x"
        label="x"
        value={props.x_coordinate}
        onChange={event => props.handleXChange(event.target.value)}
        type="number"
        margin="normal"
        placeholder="1 - 1,000"
        style = {{width: 140, fontSize: 20}} 
        variant="outlined"
      />
      &nbsp; &nbsp; &nbsp;
      <TextField
        inputProps={{inputMode: 'numeric', style: {fontSize: 20}}}
        id="y"
        label="y"
        value={props.y_coordinate}
        onChange={event => props.handleYChange(event.target.value)}
        type="number"
        margin="normal"
        placeholder="1 - 1,000"
        style = {{width: 140}} 
        variant="outlined"
      />
      <div>
      <ColorPicker
        color={props.hex}
        onChangeComplete={ props.handleColorChange }
      />
      </div>
      </MuiThemeProvider>
      <div>
      <p> </p>
      </div>
      <div style={{lineHeight: 2}}>
      <Button
       disabled={!buyOk}
       style={{
        ...button_style.button,
        ...(!buyOk ? button_style.buttonDisabled : {}),
       }}
       onClick={() => props.handleBuyClick(pixel_number, props.hex)}> Buy Pixel {(pixel_number).toLocaleString(undefined, {minimumFractionDigits: 0})}
      </Button>
      </div>
      <div>
      <p> </p>
      </div>
      </div>
  )
}

const ChangePixelColor = props => {
  var buyOk = false
  if (props.changePixel !== '') {
    var buyOk = true
  }
  return (
    <div className="TextField-without-border-radius">
      <MuiThemeProvider theme={theme}>
      <TextField
        inputProps={{inputMode: 'numeric', style: {fontSize: 20}}}
        id="pixel"
        label="pixel"
        value={props.changePixel}
        onChange={event => props.handleChangePixelChange(event.target.value)}
        type="number"
        margin="normal"
        placeholder="1 - 1,000,000"
        style = {{width: 300, fontSize: 20}} 
        variant="outlined"
      />
      <div>
      <ColorPicker
        color={props.hex}
        onChangeComplete={ props.handleColorChange }
      />
      </div>
      </MuiThemeProvider>
      <div>
      <p> </p>
      </div>
      <div style={{lineHeight: 2}}>
      <Button
       disabled={!buyOk}
       style={{
        ...button_style.button,
        ...(!buyOk ? button_style.buttonDisabled : {}),
       }}
       onClick={() => props.handleChangeBuyClick(props.changePixel, props.hex)}>Change Pixel
      </Button>
      </div>
      <div>
      <p> </p>
      </div>
      </div>
  )
}

const PopUp = props => {
  if (props.popUpState === false) {
    return null
  }
  return (
    <Snackbar
        open
        >
          <SnackbarContent
            style={{
             ...snackbar_style.standardMessage
             }}
            message={
              <span id="client-snackbar">
              {props.popUpMessage}
              </span>}
            action={[
              <IconButton key="close" aria-label="close" color="inherit" onClick={() => props.handlePopUpClick()}>
                <CloseIcon className='close-icon' />
              </IconButton>
            ]}
          />
    </Snackbar>
  )
}

class ActionsApp extends React.Component {
  state = {
    selectedOption: '',
    selectedAddress: '',
    approval: {value: false},
    stackId: null,
    web3_initiated: false,
    x_coordinate: '',
    y_coordinate: '',
    hex: '#000000',
    buyDrawerOpen: false,
    popUp: false,
    popUpMessage: '',
    pixelNumber: '',
    changeDrawerOpen: false,
    changePixel: '',
    // changeHex: '#000000',
  }

  handleBuyClick = (pixel, color) => {
    axios.get(configuration.token_sold_url + pixel).then((response) => {
      this.setState({ popUpMessage: '', popUp: false })
      // console.log(response)
      // console.log(response.data.sold)
      if (response.data.sold === true) {
        this.setState({ popUp: true, popUpMessage: 'Pixel already sold 😢' })
      } else {
        this.setState( { buyDrawerOpen: true, pixelNumber: pixel } )
      }
      })
      .catch(function (error) {
        console.log(error)
        this.setState( { buyDrawerOpen: true, pixelNumber: pixel } )
     }.bind(this))
  }

  handleColorChange = ({ hex }) => {
    this.setState({ hex: hex, popUpMessage: '', popUp: false })
  }

  handleWeb3Error = (error) => {
    this.setState({ popUp: true, popUpMessage: error, buyDrawerOpen: false })
  }

  handleXChange = (x_coordinate) => {
    if (x_coordinate === '') {this.setState({x_coordinate: '', pixel_number: ''})} else
     {
      x_coordinate = parseInt(x_coordinate.toString().replace(NON_DIGIT, ''))
      if (x_coordinate < 1) {this.setState({x_coordinate: 1, popUpMessage: '', popUp: false})} else
        { if (x_coordinate > 1000) {this.setState({x_coordinate: 1000, popUpMessage: '', popUp: false})} else {
          this.setState({ x_coordinate: x_coordinate, popUpMessage: '', popUp: false })
        }}}
  }

  handleYChange = (y_coordinate) => {
    if (y_coordinate === '') {this.setState({y_coordinate: '', pixel_number: ''})} else
     {
      y_coordinate = parseInt(y_coordinate.toString().replace(NON_DIGIT, ''))
      if (y_coordinate < 1) {this.setState({y_coordinate: 1, popUpMessage: '', popUp: false})} else
        { if (y_coordinate > 1000) {this.setState({y_coordinate: 1000, popUpMessage: '', popUp: false})} else {
          this.setState({ y_coordinate: y_coordinate, popUpMessage: '', popUp: false })
        }}}
  }

  handleChangePixelChange = (pixel) => {
    if (pixel === '') {this.setState({changePixel: ''})} else
     {
      pixel = parseInt(pixel.toString().replace(NON_DIGIT, ''))
      if (pixel < 1) {this.setState({changePixel: 1, popUpMessage: '', popUp: false})} else
        { if (pixel > 1000000) {this.setState({changePixel: 1000000, popUpMessage: '', popUp: false})} else {
          this.setState({ changePixel: pixel, popUpMessage: '', popUp: false })
        }}}
  }

  /*
  handleChangeColorChange = ({ changeHex }) => {
    this.setState({ changeHex: changeHex, popUpMessage: '', popUp: false })
  }
  */

  handleChangeBuyClick = () => {
    this.setState({ popUpMessage: '', popUp: false })
    this.setState( { changeDrawerOpen: true } )
  }

  handleDismissClick = () => {
    this.setState({buyDrawerOpen: false, popUpMessage: '', popUp: false})
  }

  handleDismissClickChange = () => {
    this.setState({changeDrawerOpen: false, popUpMessage: '', popUp: false})
  }

  handlePopUpClick = () => {
    this.setState({ popUp: false, popUpMessage: '' })
  }
  
  // <p style={{fontWeight: 'bold', 'fontSize': '1.4rem', lineHeight: 0.1, color: 'grey'}}>0.02 ETH</p>

  render() {
      let CollapsibleComponent;
      let CollapsibleHead;
      let CollapsibleContent;
      if (typeof window !== 'undefined') {
        CollapsibleComponent = require('react-collapsible-component').CollapsibleComponent;
        CollapsibleHead = require('react-collapsible-component').CollapsibleHead;
        CollapsibleContent = require('react-collapsible-component').CollapsibleContent;
        // require('../styles/collapse.css')
      }
      if (!CollapsibleComponent) { return <p>Beep boop</p>; }
        return (
          <div style={{ maxWidth: `1200px`, marginBottom: `1.45rem` , 'marginLeft': '4px', 'marginRight': '4px'}} align="left">
          <div className='collapse'>
            <CollapsibleComponent>
                <CollapsibleHead><h1>Buy a pixel</h1></CollapsibleHead>
                <CollapsibleContent>
                  <div className='PixelCoordinates'>
                    <PixelCoordinates
                     x_coordinate={this.state.x_coordinate}
                     y_coordinate={this.state.y_coordinate}
                     handleXChange={this.handleXChange}
                     handleYChange={this.handleYChange}
                     handleColorChange={this.handleColorChange}
                     hex={this.state.hex}
                     handleBuyClick={this.handleBuyClick}
                    />
                  </div>
                </CollapsibleContent>
                <Divider/>
                <CollapsibleHead><h1>Change pixel color</h1></CollapsibleHead>
                <CollapsibleContent>
                  <div className='ChangePixelColor'>
                  <p>You can only change a pixel if you own the corresponding ERC-721 token</p>
                    <ChangePixelColor
                     changePixel={this.state.changePixel}
                     handleChangePixelChange={this.handleChangePixelChange}
                     handleColorChange={this.handleColorChange}
                     hex={this.state.hex}
                     handleChangeBuyClick={this.handleChangeBuyClick}
                    />
                  </div>
                </CollapsibleContent>
                <Divider/>
                <CollapsibleHead><h1>Python scripts</h1></CollapsibleHead>
                <CollapsibleContent>
                <p>Head over to <Link to="/python_scripts">this post</Link> for Python scripts to programmatically interact with Etherdoek, create the canvas from the blockchain, and for Etherdoek's ABI</p>
                </CollapsibleContent>
                <Divider/>
                <CollapsibleHead><h1>Contact</h1></CollapsibleHead>
                <CollapsibleContent>
                <p>Reach out on <a href="https://twitter.com/etherdoek">Twitter</a></p>
                </CollapsibleContent>
            </CollapsibleComponent>
          </div>
          <h4><Link to="/instructions">Need help?</Link></h4>
          <Drawer
            anchor="bottom"
            open={ this.state.buyDrawerOpen }
            onClose={this.handleDismissClick.bind(this)}
          >
          {<div
            style={{ horizontalAlign: "center", textAlign: "center"}}
            >
            <p style={{fontWeight: 'bold', 'fontSize': '1.4rem', lineHeight: 0.4}}>Buy Pixel 0.005 ETH</p>
            <BuyPixel
              pixelNumber={this.state.pixelNumber}
              hex={this.state.hex}
              handleWeb3Error={this.handleWeb3Error}
            />
           </div>}
            <div style={{lineHeight: 4}}>
            <p> </p>
            </div>
          </Drawer>
          <Drawer
            anchor="bottom"
            open={ this.state.changeDrawerOpen }
            onClose={this.handleDismissClickChange.bind(this)}
          >
          {<div
            style={{ horizontalAlign: "center", textAlign: "center"}}
            >
            <p style={{fontWeight: 'bold', 'fontSize': '1.4rem', lineHeight: 0.4}}>Change Pixel 0.001 ETH</p>
            <ChangePixel
              pixelNumber={this.state.changePixel}
              hex={this.state.hex}
              handleWeb3Error={this.handleWeb3Error}
            />
           </div>}
            <div style={{lineHeight: 4}}>
            <p> </p>
            </div>
          </Drawer>
          <PopUp
            popUpState={this.state.popUp}
            handlePopUpClick={this.handlePopUpClick}
            popUpMessage={this.state.popUpMessage}
          />
          </div>
          )
  }
}

export default ActionsApp;
